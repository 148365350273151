/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {


          /**
           *  Init Featherlight Gallery
           */

          $('a[data-gallery=gallery]').featherlightGallery({
            previousIcon: '&#9664;',     /* Code that is used as previous icon */
            nextIcon: '&#9654;',         /* Code that is used as next icon */
            galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
            galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
          });

          /**
           *  Accordion functionality
           */

          var acc = document.getElementsByClassName("question");
          var i;

          for (i = 0; i < acc.length; i++) {

            acc[i].onclick = function(e) {
              e.preventDefault();
              
              this.classList.toggle("active");
              var panel = this.nextElementSibling;

              if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
              } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
              } 

            };

          }

          /**
           *  Add shrink class to header on scroll
           */

          var body = $('body');
          $threshold = 100;
          $window = $(window);
          $window.scroll(function() {
          $scroll_position = $window.scrollTop();
            if ($scroll_position > $threshold) {
                body.addClass('shrink');
            } else {
                body.removeClass('shrink');
            }
          });  

          /**
           *  Mobile Menu trigger
           */
          $(".menu-trigger").on('click touchstart', function (e) {
            e.preventDefault();
            if(body.hasClass('menu-open')) {
              body.removeClass('menu-open');
            } else {
              body.addClass('menu-open');
            }
          });


          /**
           *  Add scroll to confirmation message support (default stuff seems broken, disabled in extras.php)
           */

          if ( $( ".contact-block-form" ).length ) {
              $(document).bind('gform_post_render', function(){
                $('html, body').animate({
                    scrollTop: $(".contact-block-form").offset().top - 200
                }, 1000);
              });
          }

          /**
           * Gravity Wiz // Gravity Forms // Kayak-style Datepicker
           * Style your Gravity Forms Datepicker like Kayak.com.
           * source: https://gist.github.com/spivurno/e4b00678717e068fcb45
           */ 

          if( window.gform ) {
            gform.addFilter( 'gform_datepicker_options_pre_init', function( options, formId, fieldId ) {

                var className = 'datepicker-clean';

                if( ! $( '#input_' + formId + '_' + fieldId ).parents( 'li.gfield' ).hasClass( className ) ) {
                    return options;
                }

                var beforeShow = options.beforeShow,
                    onClose    = options.onClose;

                options.numberOfMonths = 1;
                options.changeMonth = false;
                options.changeYear = false;
                options.showOtherMonths = true;
                options.dayNamesMin = [ 'S', 'M', 'T', 'W', 'T', 'F', 'S' ];
                options.beforeShow = function() {
                    beforeShow();
                    $( '#ui-datepicker-div' ).addClass( className );
                };
                options.onClose = function() {
                    onClose();
                    var closeInterval = setInterval( function() {
                        var $dp = $( '#ui-datepicker-div' );
                        if( ! $dp.is( ':visible' ) ) {
                            $dp.removeClass( className );
                            clearInterval( closeInterval );
                        }
                    }, 100 );
                };

                return options;
            } );
          }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
